import React, {
  useState,
  useEffect,
  useRef,
  createContext,
  useContext,
  useMemo,
} from 'react';

import Notification from '../components/Notification';
import { LOCATION_STORAGE } from '../constants/app';
import { ID_KEY, TOKEN_KEY, USER_NAME } from '../constants/auth';
import api from '../services/api';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const notificationRef = useRef();

  useEffect(() => {
    if (!userToken) {
      setIsAuthenticated(false);
      return;
    }

    (async () => {
      try {
        await api.get(`/tokens/${userToken}`);
        if (userToken) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.log(error);
        setIsAuthenticated(false);
        if (typeof error === 'string') {
          notificationRef.current.notify({
            message: error,
            color: 'danger',
          });
          return;
        }

        if (error && error.response) {
          const { message } = error.response.data;
          if (message) {
            notificationRef.current.notify({
              message,
              color: 'danger',
            });
          }
        }
      }
    })();
  }, [userToken]);

  const value = useMemo(
    () => ({
      isAuthenticated,
      signIn: (token, user) => {
        localStorage.setItem(TOKEN_KEY, token);
        localStorage.setItem(ID_KEY, user.id || user._id);
        localStorage.setItem(USER_NAME, user.fullName);
        setUserToken(token);
      },
      signOut: async () => {
        await api.delete(`/tokens/${userToken}`);
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(ID_KEY);
        localStorage.removeItem(USER_NAME);
        localStorage.removeItem(LOCATION_STORAGE);
        setUserToken(null);
      },
    }),
    [userToken, isAuthenticated],
  );

  useEffect(() => {
    setUserToken(localStorage.getItem(TOKEN_KEY));
  }, []);

  return (
    <AuthContext.Provider value={value}>
      <Notification ref={notificationRef} />
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export default AuthProvider;
