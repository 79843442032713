import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormFeedback,
  Form,
  FormText,
  Label,
} from 'reactstrap';

import * as Yup from 'yup';

import { ID_KEY } from '../../constants/auth';
import { AppContext } from '../../contexts/app';
import segments from '../../datas/segments';
import api from '../../services/api';
import cepApi from '../../services/api_cep';
import apiLocalidade from '../../services/api_localidade';
import Divider from '../Divider';
import Notification from '../Notification';
import './styles.css';

function New() {
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [last10, setLast10] = useState([]);
  const [name, setName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [category, setCategory] = useState('');
  const [smalldescription, setSmalldescription] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [states, setStates] = useState([]);
  const [state, setState] = useState({});
  const [loadingStates, setLoadingStates] = useState(false);
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [complement, setComplement] = useState('');
  const [opened, setOpened] = useState('');
  const [nonBusinessAddress, setNonBusinessAddress] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedShareMyInfo, setAcceptedShareMyInfo] = useState(false);
  const [acceptedReceiveNews, setAcceptedReceiveNews] = useState(false);
  const [declaredInformationTrue, setDeclaredInformationTrue] = useState(false);
  const [declaredKeepInformationUpdated, setDeclaredKeepInformationUpdated] = useState(false);
  const [newSuccess, setNewSuccess] = useState(false);
  const { setLoading, location } = useContext(AppContext);
  const notificationRef = useRef();

  useEffect(() => {
    if (location) {
      setZipCode(location.zipCode || '');
      setStreet(location.street || '');
      setNeighborhood(location.neighborhood || '');
      setCity(location.city || '');
      setState(location.state || '');
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      try {
        let url = '/company/last10';
        if (location?.city) {
          url = `${url}?city=${location.city}`;
        }
        const { data } = await api.get(url);
        setLast10(data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [location?.city]);

  const handleNewOtherCompany = useCallback(() => {
    setName('');
    setFantasyName('');
    setEmail('');
    setWebsite('');
    setPhone('');
    setWhatsapp('');
    setCategory('');
    setSmalldescription('');
    setZipCode('');
    setStreet('');
    setNumber('');
    setNeighborhood('');
    setState({});
    setLoadingStates(false);
    setCity('');
    setCities([]);
    setLoadingCities(false);
    setComplement('');
    setOpened('');
    setNonBusinessAddress(false);
    setAcceptedTerms(false);
    setAcceptedShareMyInfo(false);
    setAcceptedReceiveNews(false);
    setDeclaredInformationTrue(false);
    setDeclaredKeepInformationUpdated(false);
    setNewSuccess(false);
  }, []);

  const handleSearchZipCode = (searchZipCode = '') => {
    setZipCode(searchZipCode);

    if (searchZipCode.length < 8) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const { data } = await cepApi.get(`/${searchZipCode}/json`);
        if (data && data.logradouro) {
          setStreet(data.logradouro);
          setNeighborhood(data.bairro);
          setCity(data.localidade);
          setState(data.uf);
        }
        setLoading(false);
      } catch (err) {
        setZipCode('');
        setLoading(false);
        notificationRef.current.notify({
          message: 'Endereço não encontrado',
          color: 'warning',
        });
        console.log(err);
      }
    })();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    (async () => {
      try {
        setLoading(true);
        const data = {
          email,
          name,
          fantasyName,
          smalldescription,
          website,
          phone,
          whatsapp,
          category,
          opened,
          zipCode,
          street,
          number,
          neighborhood,
          state: state?.sigla,
          city: city?.value,
          region: city?.region,
          complement,
          nonBusinessAddress,
          acceptedTerms,
          acceptedShareMyInfo,
          acceptedReceiveNews,
          declaredInformationTrue,
          declaredKeepInformationUpdated,
          user: localStorage.getItem(ID_KEY),
        };

        console.log(data);
        const schema = Yup.object().shape({
          category: Yup.string().required('Selecione uma categoria'),
          // whatsapp: Yup.string().required('Informe um whatsapp'),
          phone: Yup.string().required('Informe um telefone'),
          fantasyName: Yup.string().required('Informe o nome fantasia da empresa'),
          name: Yup.string().required('Informe um nome'),
          // website: Yup.string().url('Informe uma url válida'),
          zipCode: Yup.string().required('Informe um CEP'),
          street: Yup.string().required('Informe um logradouro'),
          number: Yup.string().required('Informe um número'),
          neighborhood: Yup.string().required('Informe um bairro'),
          state: Yup.string().required('Selecione um estado'),
          city: Yup.string().required('Selecione uma cidade'),
          opened: Yup.string().required('Informe os horários de funcionamento da loja'),
          smalldescription: Yup.string()
            .max(250, 'Informe no máximo 250 caracteres')
            .required('Informe uma breve descrição do seu negócio'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('Informe um email'),
          acceptedShareMyInfo: Yup.boolean()
            .required('Precisa aceitar compartilhar seus dados para se cadastrar.')
            .oneOf([true], 'Precisa aceitar compartilhar seus dados para se cadastrar.'),
          declaredInformationTrue: Yup.boolean()
            .required('Precisa declarar que suas informações são verdadeiras.')
            .oneOf([true], 'Precisa declarar que suas informações são verdadeiras.'),
          declaredKeepInformationUpdated: Yup.boolean()
            .required('Precisa se comprometer a informar o suporte mictmr.org caso ocorra alguma alteração de dados da empresa ou não seja mais o proprietário da mesma.')
            .oneOf([true], 'Precisa se comprometer a informar o suporte mictmr.org caso ocorra alguma alteração de dados da empresa ou não seja mais o proprietário da mesma.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // validation passed
        await api.post('/companies', data);

        setLoading(false);
        setNewSuccess(true);
        notificationRef.current.notify({
          message: 'Cadastro realizado com sucesso',
        });
      } catch (err) {
        setLoading(false);
        console.log(err);
        setValidationErrors({});
        // Validation failed
        if (err instanceof Yup.ValidationError) {
          notificationRef.current.notify({
            message:
              'Por favor, preencha todos os dados para ter acesso ao portal MICTMR',
            color: 'warning',
          });
          const ve = {};
          err.inner.forEach((e) => {
            ve[e.path] = e.message;
          });
          setValidationErrors(ve);
          return;
        }

        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          notificationRef.current.notify({
            message,
            color: 'danger',
          });

          return;
        }

        notificationRef.current.notify({
          message: 'Falha ao realizar o cadastro da empresa',
          color: 'danger',
        });
      }
    })();
  };

  const handleOnChangeState = useCallback((stateValue) => {
    setCity(null);
    setState('');

    if (!stateValue?.value) {
      setCities([]);
      return;
    }

    setState(stateValue);

    (async () => {
      try {
        setLoading(true);
        setLoadingCities(true);
        const { data = [] } = await apiLocalidade.get(
          `estados/${stateValue.value}/municipios`,
        );
        setCities(
          data.map((c) => ({
            value: c?.nome,
            label: c?.nome,
            region: c?.microrregiao?.mesorregiao?.nome,
          })),
        );
        setLoading(false);
        setLoadingCities(false);
      } catch (error) {
        setLoading(false);
        setLoadingCities(false);
        console.log(error);
      }
    })();
  }, []);

  const populateStates = useCallback(() => {
    if (!states.length) {
      (async () => {
        try {
          setLoading(true);
          setLoadingStates(true);
          const { data = [] } = await apiLocalidade.get('estados?orderBy=nome');
          setStates(
            data.map((s) => ({
              value: s?.id,
              label: s?.nome,
              sigla: s?.sigla,
            })),
          );
          setLoading(false);
          setLoadingStates(false);
        } catch (error) {
          setLoading(false);
          setLoadingStates(false);
          console.log(error);
        }
      })();
    }
  }, []);

  const handleOnChangeCity = useCallback((cityValue) => {
    if (!cityValue) {
      setCity(null);
      return;
    }

    setCity(cityValue);
  }, []);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
      borderRadius: 50,
      fontSize: '1.05rem',
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: '#2b2b2b',
      color: '#fff',
      cursor: 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: '#e6332a',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#e6332a',
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#2b2b2b',
    }),
  };

  const selectStylesState = {
    ...selectStyles,
    control: (styles) => ({
      ...selectStyles.control(styles),
      borderColor: validationErrors.state ? '#e6332a' : '',
    }),
  };

  const selectStylesCity = {
    ...selectStyles,
    control: (styles) => ({
      ...selectStyles.control(styles),
      borderColor: validationErrors.city ? '#e6332a' : '',
    }),
  };

  return (
    <>
      <Notification ref={notificationRef} />
      <Row
        id="cadastrar"
        className="h-100vh align-items-center justify-content-center py-5 px-2 px-md-5"
      >
        <Col>
          <div
            className="text-white text-center pt-5 pt-sm-0"
            style={{
              marginBottom: '80px',
            }}
          >
            <h4 className="pt-5 pt-lg-3 mt-4 mt-lg-0">
              Empresas recentemente cadastradas
            </h4>
            <Divider />
          </div>
          <Row
            className="bg-gray-dark p-2 p-md-5 flex-sm-row-reverse"
            style={{
              borderRadius: '2rem',
            }}
          >
            <Col xs={12} lg={6}>
              <div className="p-3">
                {last10.map((company) => (
                  <Row className="align-items-center" key={company._id}>
                    <Col xs={2}>
                      <div className="d-flex align-items-center justify-content-center bg-white rounded-circle img-size-custom">
                        <i className="fas fa-store text-danger" />
                      </div>
                    </Col>
                    <Col xs={6} className="lh-120">
                      <div className="pl-3 pr-1 font-size-responsive-custom">
                        <strong className="text-danger">
                          {company.fantasyName}
                        </strong>
                        <br />
                        <small className="text-white">
                          {company.smalldescription}
                        </small>
                        <br />
                        <small className="text-gray">{company.name}</small>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <Button
                        className="bg-danger font-size-responsive-custom"
                        onClick={() => navigate(`empresas/${company.fantasyName}/empresas`)}
                      >
                        {'Saiba Mais >>'}
                      </Button>
                    </Col>
                    <Divider border="1" width="100%" />
                  </Row>
                ))}
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <Card className="h-100 text-center shadow-none">
                <CardHeader>
                  {newSuccess ? (
                    <>
                      <h6>
                        Cadastro realizado com sucesso aguarde a aprovação para
                        ser divulgado no portal do MICTMR
                      </h6>
                      <p>
                        Você receberá um email de confirmação de desse cadastro.
                        {' '}
                        <small>
                          Caso o email não se encontre na caixa de entrada, por
                          favor verifique seu spam
                        </small>
                      </p>
                    </>
                  ) : (
                    <h6>Cadastre sua empresa</h6>
                  )}
                </CardHeader>
                <CardBody className="text-left py-5 px-3 px-md-5">
                  {newSuccess ? (
                    <>
                      <Row className="justify-content-center">
                        <i
                          className="fas fa-check-circle text-success"
                          style={{
                            fontSize: '180pt',
                          }}
                        />
                      </Row>
                      <Button
                        color="danger"
                        className="mt-5"
                        onClick={handleNewOtherCompany}
                        block
                      >
                        Cadastrar outra Empresa
                      </Button>
                    </>
                  ) : (
                    <Form onSubmit={handleSubmit}>
                      {/* <Row className="justify-content-around mb-3">
                        <Col xs={12} sm={6} className="pb-3">
                          <CustomInput
                            type="radio"
                            id="cnpj"
                            name="person"
                            value="cnpj"
                            checked={person === 'cnpj'}
                            label="Pessoa Jurídica"
                            onChange={({ target: { value } }) => setPerson(value)}
                          />
                        </Col>
                        <Col xs={12} sm={6} className="pb-3">
                          <CustomInput
                            type="radio"
                            id="cpf"
                            name="person"
                            value="cpf"
                            checked={person === 'cpf'}
                            label="Pessoa Física"
                            onChange={({ target: { value } }) => setPerson(value)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {person === 'cnpj' ? (
                            <FormGroup>
                              <InputGroup
                                className={`input-group-merge ${
                                  validationErrors.cnpj ? 'invalid' : ''
                                }`}
                              >
                                <NumberFormat
                                  customInput={Input}
                                  format="##.###.###/####-##"
                                  type="text"
                                  placeholder="CNPJ"
                                  value={cnpj}
                                  invalid={!!validationErrors.cnpj}
                                  onValueChange={({ value }) => {
                                    setCnpj(value);
                                    setCpf('');
                                  }}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-briefcase" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <FormFeedback>
                                  {validationErrors.cnpj}
                                </FormFeedback>
                              </InputGroup>
                            </FormGroup>
                          ) : (
                            <FormGroup>
                              <InputGroup
                                className={`input-group-merge ${
                                  validationErrors.cpf ? 'invalid' : ''
                                }`}
                              >
                                <NumberFormat
                                  customInput={Input}
                                  format="###.###.###-##"
                                  type="text"
                                  placeholder="CPF"
                                  value={cpf}
                                  invalid={!!validationErrors.cpf}
                                  onValueChange={({ value }) => {
                                    setCpf(value);
                                    setCnpj('');
                                  }}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i className="fas fa-user" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <FormFeedback>
                                  {validationErrors.cpf}
                                </FormFeedback>
                              </InputGroup>
                            </FormGroup>
                          )}
                        </Col>
                      </Row> */}
                      <Row>
                        <Col>
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.name ? 'invalid' : ''
                              }`}
                            >
                              <Input
                                type="text"
                                placeholder="Nome do Irmão Responsável/Proprietário"
                                value={name}
                                invalid={!!validationErrors.name}
                                onChange={({ target: { value } }) => setName(value)}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-file-signature" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>
                                {validationErrors.name}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          {/* <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.companyName ? 'invalid' : ''
                              }`}
                            >
                              <Input
                                type="text"
                                placeholder="Razão Social"
                                value={companyName}
                                invalid={!!validationErrors.companyName}
                                onChange={({ target: { value } }) => setCompanyName(value)}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-building" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>
                                {validationErrors.companyName}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup> */}
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.fantasyName ? 'invalid' : ''
                              }`}
                            >
                              <Input
                                type="text"
                                placeholder="Nome da Empresa"
                                value={fantasyName}
                                invalid={!!validationErrors.fantasyName}
                                onChange={({ target: { value } }) => setFantasyName(value)}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-building" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>
                                {validationErrors.fantasyName}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.category ? 'invalid' : ''
                              }`}
                            >
                              <CustomInput
                                type="select"
                                id="category"
                                name="category"
                                value={category}
                                invalid={!!validationErrors.category}
                                onChange={({ target: { value } }) => setCategory(value)}
                              >
                                <option value="">Selecione um segmento</option>
                                {segments.map((c) => (
                                  <option key={c.value} value={c.value}>
                                    {c.label}
                                  </option>
                                ))}
                              </CustomInput>
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-layer-group" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>
                                {validationErrors.category}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.email ? 'invalid' : ''
                              }`}
                            >
                              <Input
                                type="email"
                                placeholder="Email"
                                value={email}
                                invalid={!!validationErrors.email}
                                onChange={({ target: { value } }) => setEmail(value.toLowerCase())}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-envelope" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>
                                {validationErrors.email}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.phone ? 'invalid' : ''
                              }`}
                            >
                              <NumberFormat
                                customInput={Input}
                                format="(##) ####-####"
                                mask="_"
                                type="text"
                                placeholder="Telefone"
                                value={phone}
                                invalid={!!validationErrors.phone}
                                onValueChange={({ value }) => setPhone(value)}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-phone" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>
                                {validationErrors.phone}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.website ? 'invalid' : ''
                              }`}
                            >
                              <Input
                                id="website"
                                type="text"
                                placeholder="Website"
                                value={website}
                                invalid={!!validationErrors.website}
                                onChange={({ target: { value } }) => setWebsite(value)}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-window-maximize" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormText className="w-100">
                                <p className="mb-0">
                                  Ex.: https://www.seusite.com.br
                                </p>
                              </FormText>
                              <FormFeedback>
                                {validationErrors.website}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.whatsapp ? 'invalid' : ''
                              }`}
                            >
                              <NumberFormat
                                customInput={Input}
                                format="(##) #####-####"
                                mask="_"
                                type="text"
                                placeholder="Whatsapp"
                                value={whatsapp}
                                invalid={!!validationErrors.whatsapp}
                                onValueChange={({ value }) => setWhatsapp(value)}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fab fa-whatsapp-square" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>
                                {validationErrors.whatsapp}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.opened ? 'invalid' : ''
                              }`}
                            >
                              <Input
                                id="opened"
                                type="text"
                                placeholder="Horários de Funcionamento"
                                value={opened}
                                invalid={!!validationErrors.opened}
                                onChange={({ target: { value } }) => setOpened(value)}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-clock" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormText className="w-100">
                                <p className="mb-0">
                                  Ex.: Segunda a Sexta das 08hs ás 18hs e Sábado
                                  das 08hs ás 17hs
                                </p>
                              </FormText>
                              <FormFeedback>
                                {validationErrors.opened}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <Label for="smalldescription">
                              Faça uma breve descrição de sua empresa/negócio
                            </Label>
                            <Input
                              id="smalldescription"
                              type="textarea"
                              value={smalldescription}
                              invalid={!!validationErrors.smalldescription}
                              onChange={({ target: { value } }) => setSmalldescription(value)}
                            />
                            <FormText className="w-100">
                              <p className="mb-0">
                                {smalldescription.length}
                                {' '}
                                caracteres
                              </p>
                              <p className="w-100">
                                Ex.: Restaurante na orla da praia especializado
                                em massas, frutos do mar e com uma ampla carta
                                de vinhos.
                              </p>
                            </FormText>
                            <FormFeedback>
                              {validationErrors.smalldescription}
                            </FormFeedback>
                          </FormGroup>
                          <Row className="align-items-center justify-content-center">
                            <Divider width="30%" />
                            <strong className="mx-1">Endereço</strong>
                            <Divider width="30%" />
                          </Row>
                          <FormGroup>
                            <InputGroup
                              className={`input-group-merge ${
                                validationErrors.zipCode ? 'invalid' : ''
                              }`}
                            >
                              <NumberFormat
                                customInput={Input}
                                format="##.###-###"
                                mask="_"
                                type="text"
                                placeholder="CEP"
                                value={zipCode}
                                invalid={!!validationErrors.zipCode}
                                onValueChange={({ value }) => handleSearchZipCode(value)}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fas fa-map-marker-alt" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>
                                {validationErrors.zipCode}
                              </FormFeedback>
                            </InputGroup>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="text"
                              placeholder="Logradouro"
                              value={street}
                              invalid={!!validationErrors.street}
                              onChange={({ target: { value } }) => setStreet(value)}
                            />
                            <FormFeedback>
                              {validationErrors.street}
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="text"
                              placeholder="Número"
                              value={number}
                              invalid={!!validationErrors.number}
                              onChange={({ target: { value } }) => setNumber(value)}
                            />
                            <FormFeedback>
                              {validationErrors.number}
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="text"
                              placeholder="Bairro"
                              value={neighborhood}
                              invalid={!!validationErrors.neighborhood}
                              onChange={({ target: { value } }) => setNeighborhood(value)}
                            />
                            <FormFeedback>
                              {validationErrors.neighborhood}
                            </FormFeedback>
                          </FormGroup>
                          <Select
                            name="states"
                            className="basic-single mb-3"
                            classNamePrefix="select"
                            placeholder="Selecione um estado"
                            noOptionsMessage={() => 'Nenhuma estado encontrado'}
                            loadingMessage={() => 'Carregando estados'}
                            isLoading={loadingStates}
                            isClearable
                            isSearchable
                            options={states}
                            styles={selectStylesState}
                            onFocus={populateStates}
                            onChange={handleOnChangeState}
                          />
                          <Select
                            name="cities"
                            className="basic-single mb-3"
                            classNamePrefix="select"
                            placeholder="Selecione uma cidade"
                            noOptionsMessage={() => (!state
                              ? 'Selecione um estado'
                              : 'Nenhuma cidade encontrada')}
                            loadingMessage={() => 'Carregando cidades'}
                            isLoading={loadingCities}
                            isClearable
                            isSearchable
                            value={city}
                            options={cities}
                            styles={selectStylesCity}
                            onChange={handleOnChangeCity}
                          />
                          <FormGroup>
                            <Input
                              type="text"
                              placeholder="Complemento"
                              value={complement}
                              onChange={({ target: { value } }) => setComplement(value)}
                            />
                          </FormGroup>
                          <CustomInput
                            type="checkbox"
                            id="nonBusinessAddress"
                            label="Se você é profissional liberal e/ou não atende em seu endereço marque aqui"
                            checked={nonBusinessAddress}
                            onChange={() => setNonBusinessAddress((prevState) => !prevState)}
                          />
                          <Divider />
                          {/* <FormGroup>
                            <CustomInput
                              type="checkbox"
                              id="acceptedTerms"
                              label="Li e aceito os termos de uso e política de privacidade"
                              checked={acceptedTerms}
                              invalid={!!validationErrors.acceptedTerms}
                              onChange={() => setAcceptedTerms((prevState) => !prevState)}
                            />
                            <a
                              href="https://mictmr.org/politica-de-privacidade-e-termos-de-uso.html"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Ler os Termos
                            </a>
                          </FormGroup> */}
                          <FormGroup>
                            <CustomInput
                              type="checkbox"
                              id="acceptedShareMyInfo"
                              label="Autorizo que os dados da minha empresa sejam compartilhados com empresas parceiras"
                              checked={acceptedShareMyInfo}
                              invalid={!!validationErrors.acceptedShareMyInfo}
                              onChange={() => setAcceptedShareMyInfo(
                                (prevState) => !prevState,
                              )}
                            />
                          </FormGroup>
                          {/* <FormGroup>
                            <CustomInput
                              type="checkbox"
                              id="acceptedReceiveNews"
                              label="Aceito receber informações e promoções"
                              checked={acceptedReceiveNews}
                              onChange={() => setAcceptedReceiveNews(
                                (prevState) => !prevState,
                              )}
                            />
                          </FormGroup> */}
                          <FormGroup>
                            <CustomInput
                              type="checkbox"
                              id="declaredInformationTrue"
                              label="Declaro que todas as informações fornecidas por mim nesse formulário são verdadeiras"
                              invalid={
                                !!validationErrors.declaredInformationTrue
                              }
                              checked={declaredInformationTrue}
                              onChange={() => setDeclaredInformationTrue(
                                (prevState) => !prevState,
                              )}
                            />
                          </FormGroup>
                          <FormGroup>
                            <CustomInput
                              type="checkbox"
                              id="declaredKeepInformationUpdated"
                              label="Me comprometo a informar o suporte mictmr.org caso ocorra alguma alteração de dados da empresa ou não seja mais o proprietário da mesma"
                              invalid={
                                !!validationErrors.declaredKeepInformationUpdated
                              }
                              checked={declaredKeepInformationUpdated}
                              onChange={() => setDeclaredKeepInformationUpdated(
                                (prevState) => !prevState,
                              )}
                            />
                          </FormGroup>
                          <Divider />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button type="submit" color="danger" size="lg" block>
                            Cadastrar minha Empresa
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default New;
