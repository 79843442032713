import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { CustomInput, FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import apiLocalidade from '../../services/api_localidade';
import Autocomplete from '../Autocomplete';
import Divider from '../Divider';

function Searchbar() {
  const navigate = useNavigate();
  const { windowSize, setLoading, location, handleChangeLocation } = useContext(AppContext);
  const [search, setSearch] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [state, setState] = useState('');
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [searchInRegion, setSearchInRegion] = useState(false);

  useEffect(() => {
    if (location) {
      if (location?.city) {
        setCity({
          value: location?.city,
          label: location?.city,
          region: location?.region,
        });
      }

      if (location?.stateId && location?.stateName) {
        setState({ value: location?.stateId, label: location?.stateName });
      }
      setSearchInRegion(!!location?.searchInRegion);
    }
  }, [location]);

  const handleSearch = useCallback(
    (value) => {
      setSearch(value);
      setCompanyList([]);

      if (value.length > 3) {
        (async () => {
          try {
            let url = `/company/search?query=${value}`;

            if (city && city.value) {
              url = `${url}${`&city=${city.value}`}`;
            }

            if (searchInRegion && city && city.region) {
              url = `${url}${`&region=${city.region}`}`;
            }
            const { data } = await api.get(url);
            setCompanyList(data);
          } catch (error) {
            console.log(error);
          }
        })();
      }
    },
    [city, searchInRegion],
  );

  const handleGoToCompany = useCallback((value) => {
    setSearch(value);
    navigate(`empresas/${value}/empresas`);
  }, []);

  const handleOnChangeState = useCallback((stateValue) => {
    setCities([]);
    setCity(null);
    setState('');

    if (!stateValue?.value) {
      return;
    }

    setState(stateValue);
    handleChangeLocation({
      ...location,
      stateId: stateValue?.value,
      stateName: stateValue?.label,
    });
  }, [location]);

  const handleOnFocusState = useCallback(() => {
    if (!states.length) {
      (async () => {
        try {
          setLoading(true);
          setLoadingStates(true);
          const { data = [] } = await apiLocalidade.get('estados?orderBy=nome');
          setStates(
            data.map((s) => ({
              value: s?.id,
              label: s?.nome,
              sigla: s?.sigla,
            })),
          );
          setLoading(false);
          setLoadingStates(false);
        } catch (error) {
          setLoading(false);
          setLoadingStates(false);
          console.log(error);
        }
      })();
    }
  }, []);

  const handleOnFocusCity = useCallback(() => {
    if (!cities.length) {
      (async () => {
        try {
          setLoading(true);
          setLoadingCities(true);
          const { data = [] } = await apiLocalidade.get(
            `estados/${state.value}/municipios`,
          );
          setCities(
            data.map((c) => ({
              value: c?.nome,
              label: c?.nome,
              region: c?.microrregiao?.mesorregiao?.nome,
            })),
          );
          setLoading(false);
          setLoadingCities(false);
        } catch (error) {
          setLoading(false);
          setLoadingCities(false);
          console.log(error);
        }
      })();
    }
  }, [state]);

  const handleOnChangeCity = useCallback((cityValue) => {
    if (!cityValue) {
      setCity('');
      return;
    }

    setCity(cityValue);
    handleChangeLocation({
      ...location,
      city: cityValue.value,
      region: cityValue?.region,
    });
  }, [location]);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#fff',
      borderRadius: 50,
      padding: '0.2rem',
      fontSize: '1.25rem',
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: '#2b2b2b',
      color: '#fff',
      cursor: 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: '#e6332a',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#e6332a',
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#2b2b2b',
    }),
  };

  return (
    <Row
      className="bg-secondary align-items-center justify-content-center py-5"
      style={{
        height: '65%',
      }}
    >
      <div className="w-75">
        <div
          className="text-white text-center"
          style={{
            marginBottom: '80px',
          }}
        >
          <h4>Encontre um Irmão próximo de você e faça bons negócios</h4>
          <Divider />
        </div>
        <Select
          name="states"
          className="basic-single mb-1"
          classNamePrefix="select"
          placeholder="Selecione um estado"
          noOptionsMessage={() => 'Nenhuma estado encontrado'}
          loadingMessage={() => 'Carregando estados'}
          isLoading={loadingStates}
          isClearable
          isSearchable
          value={state}
          options={states}
          styles={selectStyles}
          onFocus={handleOnFocusState}
          onChange={handleOnChangeState}
        />
        <Select
          name="cities"
          className="basic-single mb-3"
          classNamePrefix="select"
          placeholder="Selecione uma cidade"
          noOptionsMessage={() => (!state ? 'Selecione um estado' : 'Nenhuma cidade encontrada')}
          loadingMessage={() => 'Carregando cidades'}
          isLoading={loadingCities}
          isClearable
          isSearchable
          value={city}
          options={cities}
          styles={selectStyles}
          onFocus={handleOnFocusCity}
          onChange={handleOnChangeCity}
        />
        <FormGroup>
          <CustomInput
            type="checkbox"
            id="searchInRegion"
            label="Habilitar busca por região"
            checked={searchInRegion}
            onChange={() => setSearchInRegion((prevState) => {
              handleChangeLocation({
                ...location,
                searchInRegion: !prevState,
              });
              return !prevState;
            })}
          />
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-merge w-100 mb-1">
            <Input
              type="text"
              placeholder="Procurar empresa ou negócio"
              value={search}
              onChange={({ target: { value } }) => handleSearch(value)}
              style={{
                height: '62px',
                fontSize: windowSize.width > 576 ? '1.5rem' : '1rem',
                borderTopLeftRadius: '2rem',
                borderBottomLeftRadius: '2rem',
                paddingLeft: '1.75rem',
              }}
            />
            <InputGroupAddon
              className="cursor-pointer"
              addonType="append"
              onClick={() => navigate(`/empresas/${search}/busca`)}
            >
              <InputGroupText
                style={{
                  borderTopRightRadius: '2rem',
                  borderBottomRightRadius: '2rem',
                }}
              >
                <i className="fas fa-search bg-danger p-3 text-white rounded-circle" />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <FormText>
            <small className="text-white">
              (Digite acima o nome ou segmento da empresa que procura)
            </small>
          </FormText>
        </FormGroup>
        <Autocomplete
          list={companyList}
          onItemLabel={(item) => `${item.fantasyName} - ${item.smalldescription}`}
          onItemValue={(item) => item.fantasyName}
          onItemClick={(item) => handleGoToCompany(item.fantasyName)}
        />
      </div>
    </Row>
  );
}

export default Searchbar;
