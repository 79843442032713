import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Col,
  /* CustomInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText, */
  Row,
} from 'reactstrap';

import { AppContext } from '../../contexts/app';
import segments from '../../datas/segments';
import api from '../../services/api';
import CompanyCard from '../CompanyCard';

function Company() {
  const { location, windowSize, setLoading } = useContext(AppContext);
  const navigate = useNavigate();
  const { filter, type = '' } = useParams();
  const [companies, setCompanies] = useState([]);
  const [deliveriesInShow, setDeliveriesInShow] = useState(true);
  const [filterLabel, setFilterLabel] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        document.body.scrollTop = windowSize.width > 576 ? 170 : 0; // For Safari
        document
          .documentElement
          .scrollTop = windowSize.width > 576 ? 170 : 0; // For Chrome, Firefox, IE and Opera
        setFilterLabel(filter);
        let url = `/company/search-by-type?type=${type}`;
        if (filter) {
          url = `${url}&query=${filter}`;
        }

        if (location?.city) {
          url = `${url}&city=${location.city}`;
        }

        if (location?.searchInRegion && location?.region) {
          url = `${url}&region=${location.region}`;
        }
        console.log(url);
        const { data } = await api.get(url);
        // console.log('filterLabel:', filterLabel, 'value:', value, 'type:', type, 'data:', data);
        setDeliveriesInShow(!!filter);
        setCompanies(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [type, filter, location]);

  const handleRemoveFilter = useCallback(() => {
    setDeliveriesInShow((prevState) => !prevState);
    navigate('/empresas');
  }, []);

  return (
    <Row className="bg-secondary align-items-center justify-content-center py-5">
      <Col>
        <Row className="p-3 justify-content-between align-items-center">
          <span className="p-2">
            {deliveriesInShow && (
              <>
                <i className="fas fa-map-marker-alt mr-2" />
                Você está buscando por empresas/negócios de Irmãos em
                {' '}
                <span
                  role="button"
                  tabIndex="0"
                  className="text-danger font-weight-700"
                  onClick={handleRemoveFilter}
                  onKeyPress={handleRemoveFilter}
                >
                  {type === 'segmentos'
                    ? segments.find((c) => c.value === filterLabel)?.label
                    : filterLabel}
                  <i className="fas fa-eraser ml-2" />
                </span>
              </>
            )}
          </span>
          {/* <FormGroup>
            <InputGroup className="input-group-merge">
              <CustomInput
                type="select"
                id="ordem"
                name="ordem"
                color="danger"
                className="bg-secondary border-0 text-white"
              >
                <option>Ordenar Por</option>
              </CustomInput>
              <InputGroupAddon addonType="append">
                <InputGroupText className="bg-secondary border-0 text-white">
                  <i className="fas fa-chevron-circle-down ml-2" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup> */}
        </Row>
        <Row>
          {companies.length ? (
            companies.map((company) => (
              <Col key={company._id} xs={12} md={6} lg={4} xl={4}>
                <CompanyCard company={company} />
              </Col>
            ))
          ) : (
            <Col>
              <div className="d-flex justify-content-center text-white">
                Nenhum resultado encontrado
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default Company;
