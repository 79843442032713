import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import Cities from '../../components/Cities';
import Footer from '../../components/Footer';
import GoToTop from '../../components/GoToTop';
import Navbar from '../../components/Navbar';
import New from '../../components/New';
import Segments from '../../components/Segments';
import YourLocation from '../../components/YourLocation';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

export default function CompanyPage() {
  const { windowSize, setLoading } = useContext(AppContext);
  const { id } = useParams();
  const [company, setCompany] = useState();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        document.body.scrollTop = windowSize.width > 576 ? 160 : 0; // For Safari
        document.documentElement.scrollTop = windowSize.width > 576 ? 160 : 0; // For Chrome, Firefox, IE and Opera
        const { data } = await api.get(`/companies/${id}`);
        setCompany(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <div className="px-3">
        <YourLocation />
        <Navbar />
        <div
          className="w-100"
          style={{
            minHeight: '500px',
          }}
        >
          {company ? (
            <>
              <h1 className="text-center">{company.fantasyName}</h1>
              <h4 className="text-center">{company.companyName}</h4>
              <Card>
                <CardBody className="p-4 lh-100">
                  <Row className="mb-3">
                    <Col>
                      <h5>
                        {company.smalldescription}
                      </h5>
                      <small className="text-gray">{`Irmão ${company.name}`}</small>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <div
                        className="d-flex align-items-center text-justify lh-140"
                        style={{ fontSize: '0.75rem', height: '2.5rem' }}
                      >
                        <i className="far fa-calendar mr-2" />
                        <div>{company.opened}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <div
                        className="d-flex align-items-center text-justify lh-140"
                        style={{ fontSize: '0.75rem', height: '2.5rem' }}
                      >
                        <i className="fas fa-map-marker-alt mr-2" />
                        {company.nonBusinessAddress ? (
                          <div>Não Atende no endereço</div>
                        ) : (
                          <div>
                            {`${company.street}, ${company.number}, ${company.neighborhood},
                  ${company.zipCode} - ${company.city}, ${company.state}`}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs={12} sm={4} className="pb-2 pb-sm-0 px-lg-1">
                      <a
                        href={`tel:${company.phone}`}
                        title=""
                        className="text-decoration-none"
                      >
                        <Button
                          color="warning"
                          className="text-white h-100 py-3 py-sm-2"
                          style={{
                            borderRadius: '0.3rem',
                            fontSize: '0.65rem',
                          }}
                          block
                          size="sm"
                        >
                          <i className="fas fa-phone mr-1" />
                          <strong>Telefone</strong>
                        </Button>
                      </a>
                    </Col>
                    {company.whatsapp && (
                      <Col xs={12} sm={4} className="px-lg-1">
                        <a
                          href={`https://api.whatsapp.com/send?phone=55${company.whatsapp}&text=Saudações meu Irmão ${company.name}, peguei seu contato no portal MICTMR. Tudo Justo?`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-decoration-none"
                        >
                          <Button
                            color="success"
                            className="h-100 py-3 py-sm-2"
                            style={{
                              borderRadius: '0.3rem',
                              fontSize: '0.65rem',
                            }}
                            block
                            size="sm"
                          >
                            <i className="fab fa-whatsapp mr-1" />
                            <strong>Whatsapp</strong>
                          </Button>
                        </a>
                      </Col>
                    )}
                    {company.website && (
                      <Col xs={12} sm={4} className="px-lg-1">
                        <a
                          href={company.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-decoration-none"
                        >
                          <Button
                            color="danger"
                            className="h-100 py-3 py-sm-2"
                            style={{
                              borderRadius: '0.3rem',
                              fontSize: '0.65rem',
                            }}
                            block
                            size="sm"
                          >
                            <i className="fas fa-window-maximize mr-1" />
                            <strong>Website</strong>
                          </Button>
                        </a>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
              {company.zipCode ? (
                <Card className="mt-3">
                  <CardBody>
                    <Row>
                      <Col>
                        <div
                          style={{
                            position: 'relative',
                            overflow: 'hidden',
                            width: '100%',
                            paddingTop: '56.25%',
                          }}
                        >
                          <iframe
                            title="Localização da loja"
                            loading="lazy"
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCiOB6kqQXOCUWnKV0hCigRmfwEh4JWPIM&q=${company.street?.replace(/\s/g, '+')}+${company.number?.replace(/\s/g, '+')}+${company.neighborhood?.replace(/\s/g, '+')}+${company.city?.replace(/\s/g, '+')}+${company.state.replace(/\s/g, '+')}+${company.zipCode?.replace(/\s/g, '+')}`}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ) : null}
            </>
          ) : null}
        </div>
        <Segments />
        <Cities />
        <New />
        <GoToTop />
      </div>
      <Footer />
    </>
  );
}
