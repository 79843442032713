import React, { useEffect, useState, useContext, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import * as Yup from 'yup';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import Notification from '../Notification';

function Curriculo() {
  const navigate = useNavigate();
  const notificationRef = useRef();
  const { windowSize, setLoading } = useContext(AppContext);
  const [validationErrors, setValidationErrors] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [description, setDescription] = useState(RichTextEditor.createEmptyValue());
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        document.body.scrollTop = windowSize.width > 576 ? 170 : 0; // For Safari
        document
          .documentElement
          .scrollTop = windowSize.width > 576 ? 170 : 0; // For Chrome, Firefox, IE and Opera
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        name,
        email,
        cpf: cpf.replace(/[^\d]+/g, ''),
      };

      const schema = Yup.object().shape({
        name: Yup.string().required('Informe seu nome'),
        email: Yup.string()
          .required('Informe seu email')
          .email('Informe um email válido'),
        cpf: Yup.string().required('Informe seu CPF'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();

      formData.append('name', name);
      formData.append('email', email);
      formData.append('cpf', cpf.replace(/[^\d]+/g, ''));
      formData.append('description', description.toString('html'));

      if (pdf instanceof File) {
        formData.append('pdf', pdf);
      }

      await api.post('/curriculos', formData);
      setLoading(false);
      notificationRef.current.notify({
        message: 'Currículo Cadastrado com sucesso',
      });
      setTimeout(() => {
        navigate('/curriculos');
      }, 1500);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setValidationErrors({});
      // Validation failed
      if (err instanceof Yup.ValidationError) {
        notificationRef.current.notify({
          message: 'Por favor, preencha todos os dados necessários para cadastrar o curriculo',
          color: 'warning',
        });
        const ve = {};
        err.inner.forEach((e) => {
          ve[e.path] = e.message;
        });
        setValidationErrors(ve);
        return;
      }

      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    }
  };

  return (
    <Row className="bg-secondary align-items-center justify-content-center py-5">
      <Notification ref={notificationRef} />
      <Card className="w-75">
        <CardBody>
          <FormGroup>
            <Input
              type="text"
              placeholder="Nome"
              value={name}
              invalid={!!validationErrors.name}
              onChange={({ target: { value } }) => setName(value)}
            />
            <FormFeedback>{validationErrors.name}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              placeholder="Email"
              value={email}
              invalid={!!validationErrors.email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
            <FormFeedback>{validationErrors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <NumberFormat
              customInput={Input}
              format="###.###.###-##"
              type="text"
              placeholder="CPF"
              value={cpf}
              invalid={!!validationErrors.cpf}
              onValueChange={({ value }) => {
                setCpf(value);
              }}
            />
            <FormFeedback>{validationErrors.cpf}</FormFeedback>
          </FormGroup>
          <Row className="p-3">
            <Label>
              Faça uma descrição do seu currículo, procure usar palavras chave
              para posterior pesquisa
            </Label>
            <RichTextEditor className="text-dark" value={description} onChange={setDescription} />
          </Row>
          <FormGroup>
            <Label for="pdf">Informe um arquivo PDF do curriculo</Label>
            <Input
              id="pdf"
              type="file"
              onChange={(e) => setPdf(e.target.files[0])}
            />
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button color="danger" className="mb-3" onClick={handleSubmit}>
            Cadastrar
          </Button>
        </CardFooter>
      </Card>
    </Row>
  );
}

export default Curriculo;
