import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';

import truncateWithEllipses from '../../helpers/TruncateStringWithEllipses';

function CompanyCard({ company }) {
  const navigate = useNavigate();

  return (
    <Card className="h-100 cursor-pointer" onClick={() => navigate(`/empresas-detalhe/${company._id}`)}>
      <CardBody className="p-4 lh-100">
        <Row className="mb-3">
          <Col>
            <strong className="text-danger">{company.fantasyName}</strong>
            <br />
            <UncontrolledTooltip
              placement="top"
              target={`smalldescription${company._id}`}
            >
              {company.smalldescription}
            </UncontrolledTooltip>
            <small
              id={`smalldescription${company._id}`}
              className="text-gray text-truncate text-lowercase"
            >
              {truncateWithEllipses(company.smalldescription, 46)}
            </small>
            {company?.smalldescription?.length >= 46 && (
              <small
                className="text-nowrap"
                id={`smalldescription${company._id}`}
              >
                {' '}
                veja mais...
              </small>
            )}
            <br />
            <small className="text-gray">{`Irmão ${company.name}`}</small>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div
              className="d-flex align-items-center text-justify lh-140"
              style={{ fontSize: '0.75rem', height: '2.5rem' }}
            >
              <i className="far fa-calendar mr-2" />
              <div>{company.opened}</div>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div
              className="d-flex align-items-center text-justify lh-140"
              style={{ fontSize: '0.75rem', height: '2.5rem' }}
            >
              <i className="fas fa-map-marker-alt mr-2" />
              {company.nonBusinessAddress ? (
                <div>Não Atende no endereço</div>
              ) : (
                <div>
                  {`${company.street}, ${company.number}, ${company.neighborhood},
                  ${company.zipCode} - ${company.city}, ${company.state}`}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {/* <Col xs={12} sm={4} className="pb-2 pb-sm-0 px-lg-1">
            <Button
              color="danger"
              className="h-100 py-3 py-sm-2"
              style={{
                borderRadius: '0.3rem',
                fontSize: '0.65rem',
              }}
              block
              size="sm"
            >
              <i className="fas fa-map-marker-alt mr-1" />
              <strong>Mapa</strong>
            </Button>
          </Col> */}
          <Col xs={12} sm={4} className="pb-2 pb-sm-0 px-lg-1">
            <a
              href={`tel:${company.phone}`}
              title=""
              className="text-decoration-none"
            >
              <Button
                color="warning"
                className="text-white h-100 py-3 py-sm-2"
                style={{
                  borderRadius: '0.3rem',
                  fontSize: '0.65rem',
                }}
                block
                size="sm"
              >
                <i className="fas fa-phone mr-1" />
                <strong>Telefone</strong>
              </Button>
            </a>
          </Col>
          {company.whatsapp && (
            <Col xs={12} sm={4} className="px-lg-1">
              <a
                href={`https://api.whatsapp.com/send?phone=55${company.whatsapp}&text=Saudações meu Irmão ${company.name}, peguei seu contato no portal MICTMR. Tudo Justo?`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                <Button
                  color="success"
                  className="h-100 py-3 py-sm-2"
                  style={{
                    borderRadius: '0.3rem',
                    fontSize: '0.65rem',
                  }}
                  block
                  size="sm"
                >
                  <i className="fab fa-whatsapp mr-1" />
                  <strong>Whatsapp</strong>
                </Button>
              </a>
            </Col>
          )}
          {company.website && (
            <Col xs={12} sm={4} className="px-lg-1">
              <a
                href={company.website}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                <Button
                  color="danger"
                  className="h-100 py-3 py-sm-2"
                  style={{
                    borderRadius: '0.3rem',
                    fontSize: '0.65rem',
                  }}
                  block
                  size="sm"
                >
                  <i className="fas fa-window-maximize mr-1" />
                  <strong>Website</strong>
                </Button>
              </a>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}

export default CompanyCard;
