/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import Slider from 'react-slick';
import Select from 'react-select';
import { Row } from 'reactstrap';

import { AppContext } from '../../contexts/app';
import segments from '../../datas/segments';
import Divider from '../Divider';
import './styles.css';

function Segments() {
  const [segment, setSegment] = useState();
  const navigation = useNavigate();
  const { windowSize } = useContext(AppContext);
  /* const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          backgroundColor: '#333',
          borderRadius: '50%',
        }}
        onClick={onClick}
      />
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          backgroundColor: '#333',
          borderRadius: '50%',
        }}
        onClick={onClick}
      />
    );
  }; */

  /* const responsive = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 2,
        rows: 2,
      },
    },
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ]; */

  const handleGoToCompany = useCallback((segmentValue) => {
    setSegment(segmentValue);
    navigation(`/empresas/${segmentValue.value}/segmentos`);
  }, []);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#e6332a',
      borderRadius: 50,
      padding: '0.5rem',
      fontSize: '1.25rem',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: '#2b2b2b',
      color: '#fff',
      cursor: 'pointer',

      ':active': {
        ...styles[':active'],
        backgroundColor: '#e6332a',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#e6332a',
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#2b2b2b',
    }),
  };

  return (
    <Row
      id="segmentos"
      className="h-100vh align-items-center justify-content-center py-5"
    >
      <div
        className="text-white text-center"
        style={{
          marginBottom: '80px',
        }}
      >
        <h4 className="px-1 pt-5 pt-lg-3 mt-4 mt-lg-0">
          Ou selecione um dos segmentos
        </h4>
        <Divider />
      </div>
      <div className={windowSize.width > 576 ? 'w-75' : 'w-100 px-3'}>
        <Select
          name="states"
          className="basic-single mb-1"
          classNamePrefix="select"
          placeholder="Selecione um segmento"
          noOptionsMessage={() => 'Nenhum segmento encontrado'}
          isClearable
          isSearchable
          value={segment}
          options={segments}
          styles={selectStyles}
          onChange={handleGoToCompany}
        />
        {/* <Slider
            responsive={responsive}
            slidesToShow={4}
            slidesToScroll={4}
            speed={1000}
            autoplaySpeed={5000}
            autoplay
            infinite
            touchMove
            pauseOnFocus
            dots
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
          >
            {segments.map(
              (category) => category.img && (
              <div key={category.value} className="carousel-container-item">
                <Card
                  className="p-3 shadow-none carousel-card-item"
                  onClick={() => navigation(`/empresas/${category.value}/segmentos`)}
                >
                  <img src={category.img} alt={category.label} />
                  <strong>{category.label}</strong>
                </Card>
              </div>
              ),
            )}
          </Slider> */}
      </div>
    </Row>
  );
}

export default Segments;
