import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import { useAuth } from './app/hooks/auth';
import AppRoutes from './app/routes/app.routes';
import AuthRoutes from './app/routes/auth.routes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-notification-alert/dist/animate.css';
import './app/assets/vendor/fontawesome-free-5.15.2-web/css/all.min.css';
import './app/assets/css/bootstrap.css';
import './app/assets/css/custom.css';

const loading = (
  <div
    className="bg-primary opacity-7 d-flex w-100 vh-100 align-items-center justify-content-center position-fixed left-0 top-0"
    style={{ zIndex: 999999 }}
  >
    <Spinner type="grow" />
  </div>
);

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Suspense fallback={loading}>
        {isAuthenticated ? (
          <AppRoutes />
        ) : (
          <AuthRoutes />
        )}
      </Suspense>
    </Router>
  );
};

export default App;
