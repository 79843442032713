import React, { useContext, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Navbar, Row } from 'reactstrap';

import Cities from '../../components/Cities';
import Footer from '../../components/Footer';
import GoToTop from '../../components/GoToTop';
import New from '../../components/New';
import Segments from '../../components/Segments';
import YourLocation from '../../components/YourLocation';
import { AppContext } from '../../contexts/app';
import api from '../../services/api';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Curriculo() {
  const { windowSize, setLoading } = useContext(AppContext);
  const { id } = useParams();
  const [curriculo, setCurriculo] = useState({});
  const [pages, setPages] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        document.body.scrollTop = windowSize.width > 576 ? 160 : 0; // For Safari
        document.documentElement.scrollTop = windowSize.width > 576 ? 160 : 0; // For Chrome, Firefox, IE and Opera
        const { data } = await api.get(`/curriculos/${id}`);
        setCurriculo(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <div className="px-3">
        <YourLocation />
        <Navbar />
        <div
          className="w-100"
          style={{
            minHeight: '500px',
          }}
        >
          <Row className="bg-secondary align-items-center justify-content-center py-5">
            <Card>
              <CardBody>
                <CardHeader>
                  <h3>{curriculo.name}</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <strong>Email:</strong>
                    &nbsp;
                    {curriculo.email}
                  </Row>
                  <Row>
                    <strong>CPF:</strong>
                    &nbsp;
                    {curriculo.cpf}
                  </Row>
                  <Row>
                    <h5>Descrição:</h5>
                  </Row>
                  <div>{ReactHtmlParser(curriculo.description)}</div>
                  <Row>
                    <Col>
                      <Document
                        file={curriculo.pdf_url}
                        error="Não foi possível carregar o pdf"
                        loading="Carregando Pdf"
                        onLoadSuccess={({ numPages }) => {
                          setPages(numPages);
                        }}
                      >
                        <Page pageNumber={page} />
                      </Document>
                    </Col>
                  </Row>
                  {pages && (
                    <Row className="justify-content-between">
                      <Button
                        color="danger"
                        onClick={() => setPage((p) => (p > 1 ? p - 1 : 1))}
                      >
                        {'<'}
                      </Button>
                      <small>{`Página ${page} de ${pages}`}</small>
                      <Button
                        color="danger"
                        onClick={() => setPage((p) => (p < pages ? p + 1 : pages))}
                      >
                        {'>'}
                      </Button>
                    </Row>
                  )}
                </CardBody>
              </CardBody>
            </Card>
          </Row>
        </div>
        <Segments />
        <Cities />
        <New />
        <GoToTop />
      </div>
      <Footer />
    </>
  );
}

export default Curriculo;
