import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Pages
const Login = React.lazy(() => import('../pages/Login'));
const Register = React.lazy(() => import('../pages/Register'));
const ForgetPassword = React.lazy(() => import('../pages/ForgetPassword'));
const RecoverPassword = React.lazy(() => import('../pages/RecoverPassword'));

export const AuthRoutes = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/cadastro" element={<Register />} />
    <Route path="/esquecisenha" element={<ForgetPassword />} />
    <Route path="/alterarsenha/:token" element={<RecoverPassword />} />
    <Route path="*" element={<Login />} />
  </Routes>
);

export default AuthRoutes;
