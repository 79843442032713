import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

function CurriculoCard({ curriculo }) {
  const navigate = useNavigate();

  return (
    <Card
      className="cursor-pointer min-vh-100"
      onClick={() => navigate(`/curriculos-detalhe/${curriculo._id}`)}
    >
      <CardBody className="p-4 lh-100">
        <Row className="mb-3">
          <Col>
            <strong>Nome:</strong>
            {' '}
            {curriculo.name}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <strong>Email:</strong>
            {' '}
            {curriculo.email}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <strong>Descrição:</strong>
            <br />
            {ReactHtmlParser(curriculo.description)}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default CurriculoCard;
