import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import CurriculoCard from '../CurriculoCard';

function Curriculo() {
  const navigate = useNavigate();
  const { windowSize, setLoading } = useContext(AppContext);
  const [filter, setFilter] = useState('');
  const [curriculos, setCurriculos] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        document.body.scrollTop = windowSize.width > 576 ? 170 : 0; // For Safari
        document
          .documentElement
          .scrollTop = windowSize.width > 576 ? 170 : 0; // For Chrome, Firefox, IE and Opera
        const url = `/curriculosFilter?query=${filter}`;
        const { data } = await api.get(url);
        setCurriculos(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [filter]);

  return (
    <Row className="bg-secondary align-items-center justify-content-center py-5">
      <Row className="p-3 justify-content-center align-items-center w-100">
        <div className="w-75">
          <Button
            color="danger"
            className="mb-3"
            onClick={() => navigate('/curriculos-cadastrar')}
          >
            Cadastrar
          </Button>
          <FormGroup>
            <InputGroup className="input-group-merge w-100 mb-1">
              <Input
                type="text"
                placeholder="Procurar por currículos"
                value={filter}
                onChange={({ target: { value } }) => setFilter(value)}
                style={{
                  height: '62px',
                  fontSize: windowSize.width > 576 ? '1.5rem' : '1rem',
                  borderTopLeftRadius: '2rem',
                  borderBottomLeftRadius: '2rem',
                  paddingLeft: '1.75rem',
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText
                  style={{
                    borderTopRightRadius: '2rem',
                    borderBottomRightRadius: '2rem',
                  }}
                >
                  <i className="fas fa-search bg-danger p-3 text-white rounded-circle" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <FormText>
              <small className="text-white">
                (Digite acima o nome ou palavra chave do curriculo que procura)
              </small>
            </FormText>
          </FormGroup>
        </div>
      </Row>
      <Row className="w-100">
        {curriculos.map((curriculo) => (
          <Col key={curriculo._id} xs={12} md={6}>
            <CurriculoCard curriculo={curriculo} />
          </Col>
        ))}
      </Row>
    </Row>
  );
}

export default Curriculo;
