import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

import ShareButtons from '../ShareButtons';

// import logo from '../../assets/img/logo.svg';

function Footer() {
  return (
    <>
      {/* <Row
        id="contatos"
        className="p-5 align-items-center justify-content-center justify-content-md-between"
      >
        <Col
          xs={6}
          sm={4}
          md={3}
          className="text-md-right text-center text-white my-1 pr-4"
          style={{
            borderRight: '3px solid var(--danger)',
          }}
        >
          <strong>
            Plataforma
            {' '}
            <span
              style={{
                wordBreak: 'keep-all',
              }}
            >
              desenvolvida e
            </span>
            {' '}
            idealizada por
          </strong>
        </Col>
        <img
          src={logo}
          alt="logo"
          className="p-3"
          style={{
            maxWidth: '180px',
            width: '100%',
          }}
        />
        <a
          target="_blank"
          href="https://vertex.ppg.br/"
          rel="noopener noreferrer"
        >
          <Button color="danger" size="sm" className="p-3">
            {'Clique e conheça o site >>'}
          </Button>
        </a>
        <a
          target="_blank"
          href="https://mictmr.org/suporte/"
          rel="noopener noreferrer"
        >
          <Button color="success" size="sm" className="p-3 mt-2 mt-sm-0">
            Suporte
          </Button>
        </a>
      </Row> */}
      <div
        id="contatos"
        className="d-flex bg-dark p-5 align-items-center justify-content-center w-100 mt-5 text-center"
      >
        <strong>
          <ShareButtons />
          <small>
            Todos os direitos reservados. Desenvolvido por mictmr.org apenas
            para membros da Maçonaria usufruir.
          </small>
        </strong>
      </div>
      <a
        target="_blank"
        href="https://mictmr.org/suporte.html"
        rel="noopener noreferrer"
      >
        <UncontrolledTooltip target="suporte">Suporte</UncontrolledTooltip>
        <Button
          id="suporte"
          color="success"
          className="position-fixed bottom-3 right-3"
        >
          <i className="fas fa-headset" />
        </Button>
      </a>
    </>
  );
}

export default Footer;
