import React, { useRef, useState, useEffect } from 'react';

import './styles.css';

export default function Autocomplete({
  list,
  children,
  width = '100%',
  onItemValue = () => null,
  onItemLabel = () => null,
  onItemClick = () => null,
}) {
  const [show, setShow] = useState(false);
  const listRef = useRef(null);
  // const currentItem = -1;

  useEffect(() => {
    if (list) { setShow(!!list.length); }
  }, [list]);

  const handleItemClick = (item) => {
    if (onItemClick) {
      onItemClick(item);
    }
    setShow(false);
  };

  /* const handleKeyDown = (event) => {
    const { keyCode } = event;
    console.log(event);

    if (keyCode === 40) {
      // Down Key
      currentItem += 1;
    } else if (keyCode === 38) {
      // Up Key
      if (currentItem > -1) {
        currentItem -= 1;
      }
    } else if (keyCode === 13) {
      // Enter Key
      event.preventDefault();
    }
  }; */

  return (
    <div className="autocomplete">
      {show && (
        <div style={{ width }}>
          {children}
          {list && (
            <ul ref={listRef} role="menu">
              {list.map((item) => (
                <li
                  role="menuitem"
                  key={item._id}
                  value={item.value || onItemValue(item)}
                  onKeyPress={() => handleItemClick(item)}
                  onClick={() => handleItemClick(item)}
                >
                  {item.label || onItemLabel(item)}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
