import React from 'react';

import Cities from '../../components/Cities';
import Company from '../../components/Company';
import Footer from '../../components/Footer';
import GoToTop from '../../components/GoToTop';
import Navbar from '../../components/Navbar';
import New from '../../components/New';
import Segments from '../../components/Segments';
import YourLocation from '../../components/YourLocation';

export default function CompaniesPage() {
  return (
    <>
      <div className="px-3">
        <YourLocation />
        <Navbar />
        <Company />
        <Segments />
        <Cities />
        <New />
        <GoToTop />
      </div>
      <Footer />
    </>
  );
}
