import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useMemo,
} from 'react';

import Loader from '../components/FullScreenLoader';
import { LOCATION_STORAGE } from '../constants/app';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const [navbarTogglerOpen, setNavbarTogglerOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setNavbarTogglerOpen(window.innerWidth > 576);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    setLocation(JSON.parse(localStorage.getItem(LOCATION_STORAGE)));

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChangeLocation = useCallback((newLocation = {}) => {
    localStorage.setItem(LOCATION_STORAGE, JSON.stringify(newLocation));
    setLocation(newLocation);
  }, []);

  const value = useMemo(
    () => ({
      navbarTogglerOpen,
      setNavbarTogglerOpen,
      location,
      handleChangeLocation,
      windowSize,
      loading,
      setLoading,
    }),
    [navbarTogglerOpen, location, windowSize, loading],
  );

  return (
    <AppContext.Provider value={value}>
      <Loader loading={loading} />
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
