import React, { useEffect, useContext } from 'react';
import { Row, Navbar, Collapse, Nav, NavItem, NavLink } from 'reactstrap';

import logomictmr from '../../assets/img/logo-mictmr-org.png';
import { AppContext } from '../../contexts/app';
import './styles.css';

function NavbarComponent() {
  const { navbarTogglerOpen } = useContext(AppContext);

  useEffect(() => {
    const toggleNavbar = () => {
      const elem = document.getElementById('navbar-top-fixed');
      if (elem) {
        if (
          document.body.scrollTop > 150
          || document.documentElement.scrollTop > 150
        ) {
          elem.style.top = '0';
        } else {
          elem.style.top = '-220px';
        }
      }
    };
    document.addEventListener('scroll', toggleNavbar, false);

    return () => document.removeEventListener('scroll', toggleNavbar, false);
  }, []);

  const NavbarItems = () => (
    <div className="d-flex flex-wrap align-items-center justify-content-center">
      <NavItem>
        <NavLink className="text-white" href="/#home">
          Início
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="text-white" href="#segmentos">
          Segmentos
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="text-white" href="#cidades">
          Cidades
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="text-white"
          target="_blank"
          href="https://mictmr.org/suporte.html"
        >
          Suporte
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="text-white bg-danger rounded" href="#cadastrar">
          Cadastrar Empresa
        </NavLink>
      </NavItem>
      <NavItem className="ml-2">
        <NavLink className="text-white bg-danger rounded" href="/curriculos">
          Currículos
        </NavLink>
      </NavItem>
    </div>
  );

  return (
    <>
      <Row
        id="navbar-top-fixed"
        className="bg-primary d-flex justify-content-center justify-content-lg-between px-sm-4 opacity-8"
      >
        <img
          src={logomictmr}
          alt=""
          style={{
            width: '100%',
            height: '100%',
            maxWidth: '300px',
          }}
        />
        <Navbar>
          <Collapse isOpen>
            <Nav>
              <NavbarItems />
            </Nav>
          </Collapse>
        </Navbar>
      </Row>
      <Row
        className={`align-items-center justify-content-center justify-content-lg-between ${
          navbarTogglerOpen ? 'py-5 px-4' : 'd-none'
        }`}
      >
        <img
          src={logomictmr}
          alt=""
          style={{
            width: '100%',
            height: '100%',
            maxWidth: '300px',
          }}
        />
        <Navbar>
          <Collapse isOpen={navbarTogglerOpen}>
            <Nav>
              <NavbarItems />
            </Nav>
          </Collapse>
        </Navbar>
      </Row>
    </>
  );
}

export default NavbarComponent;
