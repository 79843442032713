import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Companies from '../pages/Companies';
import Company from '../pages/Company';
import Curriculo from '../pages/Curriculo';
import Curriculos from '../pages/Curriculos';
import CurriculosNew from '../pages/CurriculosNew';
import Home from '../pages/Home';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="empresas" element={<Companies />} />
    <Route path="empresas/:filter/:type" element={<Companies />} />
    <Route path="empresas-detalhe/:id" element={<Company />} />
    <Route path="curriculos" element={<Curriculos />} />
    <Route path="curriculos-cadastrar" element={<CurriculosNew />} />
    <Route path="curriculos-detalhe/:id" element={<Curriculo />} />
    <Route path="*" element={<Home />} />
  </Routes>
);

export default AppRoutes;
