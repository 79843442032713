import React, { useEffect, useState, useCallback } from 'react';
import { Fade, Button } from 'reactstrap';

function GoToTop() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const showGoToTop = () => {
      setShow(
        document.body.scrollTop > 150
          || document.documentElement.scrollTop > 150,
      );
    };
    document.addEventListener('scroll', showGoToTop, false);

    return () => document.removeEventListener('scroll', showGoToTop, false);
  }, []);

  const handleTop = useCallback(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  return (
    <Fade in={show} className="z-index-99999">
      <Button
        id="go-to-top"
        color="danger"
        className="position-fixed bottom-5 right-5 rounded-circle opacity-6 z-index-99999"
        onClick={handleTop}
      >
        <i className="fas fa-arrow-up" />
      </Button>
    </Fade>
  );
}

export default GoToTop;
