import React from 'react';
import {
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { Container, Row } from 'reactstrap';

function ShareButtons() {
  return (
    <Container className="mb-5">
      <Row className="mb-2">
        <strong>
          Clique nos botões e compartilhe no Grupo de sua Loja e com outros
          Irmãos
        </strong>
      </Row>
      <Row className="justify-content-center">
        <EmailShareButton
          body={`
Saudações meu Ir.:\n
Já me cadastrei no Portal mictmr.org, uma plataforma idealizada por Maçons com o intuito de estimular e fomentar negócios Justos e Perfeitos entre Irmãos. Acesse o link abaixo e faça seu cadastro agora!

Façamos bons negócios!

TFA

https://mictmr.org/
          `}
          url=" "
          className="mx-2"
        >
          <EmailIcon size={36} round />
        </EmailShareButton>
        <TelegramShareButton
          title={`
Saudações meu Ir.:\n
Já me cadastrei no Portal mictmr.org, uma plataforma idealizada por Maçons com o intuito de estimular e fomentar negócios Justos e Perfeitos entre Irmãos. Acesse o link abaixo e faça seu cadastro agora!

Façamos bons negócios!

TFA

https://mictmr.org/
          `}
          url=" "
          className="mx-2"
        >
          <TelegramIcon size={36} round />
        </TelegramShareButton>
        <WhatsappShareButton
          title={`
Saudações meu Ir.:\n
Já me cadastrei no Portal mictmr.org, uma plataforma idealizada por Maçons com o intuito de estimular e fomentar negócios Justos e Perfeitos entre Irmãos. Acesse o link abaixo e faça seu cadastro agora!

Façamos bons negócios!

TFA

https://mictmr.org/
          `}
          url=" "
          className="mx-2"
        >
          <WhatsappIcon size={36} round />
        </WhatsappShareButton>
      </Row>
    </Container>
  );
}

export default ShareButtons;
