/* import armarinhovariedades from '../assets/img/armarinhos.svg';
import auto from '../assets/img/auto.svg';
import imobiliarias from '../assets/img/imobiliaria.svg';
import construcao from '../assets/img/construcao.svg';
import distribuidora from '../assets/img/distribuidora.svg';
import embalagem from '../assets/img/embalagens.svg';
import industria from '../assets/img/industria.svg';
import supermercados from '../assets/img/supermercados.svg';
import software from '../assets/img/moveis-eletro.svg';
import curso from '../assets/img/curso.svg';
import pet from '../assets/img/pets.svg';
import refeicao from '../assets/img/refeicoes.svg';
// import festaslazerdiversao from '../assets/img/sobremesas.svg';
import beleza from '../assets/img/beleza.svg';
import servicos from '../assets/img/servicos.svg';
import vestuario from '../assets/img/vestuarios.svg'; */

export default [
  {
    value: 'ACADEMIA',
    label: 'ACADEMIA',
  },
  {
    value: 'ADVOGADOS',
    label: 'ADVOGADOS',
  },
  {
    value: 'AGÊNCIA DE MARKETING',
    label: 'AGÊNCIA DE MARKETING',
  },
  {
    value: 'ALIMENTAÇÃO',
    label: 'ALIMENTAÇÃO',
  },
  {
    value: 'ANIMAIS',
    label: 'ANIMAIS',
  },
  {
    value: 'ANTIGUIDADES',
    label: 'ANTIGUIDADES',
  },
  {
    value: 'ASSOCIAÇÕES',
    label: 'ASSOCIAÇÕES',
  },
  {
    value: 'ATACADO',
    label: 'ATACADO',
  },
  {
    value: 'AUTOMÓVEIS',
    label: 'AUTOMÓVEIS',
  },
  {
    value: 'AUTOPEÇAS',
    label: 'AUTOPEÇAS',
  },
  {
    value: 'BANCOS',
    label: 'BANCOS',
  },
  {
    value: 'BARES E RESTAURANTES',
    label: 'BARES E RESTAURANTES',
  },
  {
    value: 'BELEZA',
    label: 'BELEZA',
  },
  {
    value: 'BUFFETS',
    label: 'BUFFETS',
  },
  {
    value: 'CALHAS',
    label: 'CALHAS',
  },
  {
    value: 'COMUNICAÇÃO',
    label: 'COMUNICAÇÃO',
  },
  {
    value: 'CONSTRUÇÕES',
    label: 'CONSTRUÇÕES',
  },
  {
    value: 'CONVENIÊNCIAS',
    label: 'CONVENIÊNCIAS',
  },
  {
    value: 'COOPERATIVAS',
    label: 'COOPERATIVAS',
  },
  {
    value: 'CURSOS',
    label: 'CURSOS',
  },
  {
    value: 'DENTISTA',
    label: 'DENTISTA',
  },
  {
    value: 'DISTRIBUIDORA',
    label: 'DISTRIBUIDORA',
  },
  {
    value: 'EDUCAÇÃO',
    label: 'EDUCAÇÃO',
  },
  {
    value: 'ERVATEIRAS',
    label: 'ERVATEIRAS',
  },
  {
    value: 'ESCOLA',
    label: 'ESCOLA',
  },
  {
    value: 'ESCRITÓRIOS CONTÁBEIS',
    label: 'ESCRITÓRIOS CONTÁBEIS',
  },
  {
    value: 'ESPORTE',
    label: 'ESPORTE',
  },
  {
    value: 'ESQUADRIAS',
    label: 'ESQUADRIAS',
  },
  {
    value: 'ESTÉTICA',
    label: 'ESTÉTICA',
  },
  {
    value: 'FARMÁCIAS',
    label: 'FARMÁCIAS',
  },
  {
    value: 'FESTA',
    label: 'FESTA',
  },
  {
    value: 'FOTO E SOM',
    label: 'FOTO E SOM',
  },
  {
    value: 'FUNERÁRIAS',
    label: 'FUNERÁRIAS',
  },
  {
    value: 'GRÁFICAS',
    label: 'GRÁFICAS',
  },
  {
    value: 'HOTÉIS',
    label: 'HOTÉIS',
  },
  {
    value: 'IMOBILIÁRIAS',
    label: 'IMOBILIÁRIAS',
  },
  {
    value: 'INDÚSTRIAS',
    label: 'INDÚSTRIAS',
  },
  {
    value: 'INFORMÁTICA',
    label: 'INFORMÁTICA',
  },
  {
    value: 'INTERNET',
    label: 'INTERNET',
  },
  {
    value: 'JARDINAGEM',
    label: 'JARDINAGEM',
  },
  {
    value: 'LAZER',
    label: 'LAZER',
  },
  {
    value: 'LIVRARIAS',
    label: 'LIVRARIAS',
  },
  {
    value: 'LOJAS',
    label: 'LOJAS',
  },
  {
    value: 'LOTÉRICAS',
    label: 'LOTÉRICAS',
  },
  {
    value: 'MÉDICOS',
    label: 'MÉDICOS',
  },
  {
    value: 'MOTOS',
    label: 'MOTOS',
  },
  {
    value: 'OFICINAS MECÂNICAS',
    label: 'OFICINAS MECÂNICAS',
  },
  {
    value: 'ÓTICAS',
    label: 'ÓTICAS',
  },
  {
    value: 'PADARIA',
    label: 'PADARIA',
  },
  {
    value: 'POSTOS DE COMBUSTÍVEIS',
    label: 'POSTOS DE COMBUSTÍVEIS',
  },
  {
    value: 'REFRIGERAÇÃO',
    label: 'REFRIGERAÇÃO',
  },
  {
    value: 'SEGURANÇA',
    label: 'SEGURANÇA',
  },
  {
    value: 'SEGUROS',
    label: 'SEGUROS',
  },
  {
    value: 'SERVIÇOS',
    label: 'SERVIÇOS',
  },
  {
    value: 'SERVIÇOS PÚBLICOS',
    label: 'SERVIÇOS PÚBLICOS',
  },
  {
    value: 'SINDICATOS',
    label: 'SINDICATOS',
  },
  {
    value: 'SOFTWARE',
    label: 'SOFTWARE',
  },
  {
    value: 'SUPERMERCADOS',
    label: 'SUPERMERCADOS',
  },
  {
    value: 'TECNOLOGIA',
    label: 'TECNOLOGIA',
  },
  {
    value: 'TURISMO',
    label: 'TURISMO',
  },
  {
    value: 'VIDRAÇARIA',
    label: 'VIDRAÇARIA',
  },
  {
    value: 'OUTROS',
    label: 'OUTROS',
  },
];
