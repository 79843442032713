import React from 'react';

import Cities from '../../components/Cities';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import New from '../../components/New';
import Searchbar from '../../components/Searchbar';
import Segments from '../../components/Segments';
import YourLocation from '../../components/YourLocation';

export default function Home() {
  return (
    <>
      <div className="px-3">
        <YourLocation />
        <Navbar />
        <Searchbar />
        <Segments />
        <Cities />
        <New />
      </div>
      <Footer />
    </>
  );
}
