import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';

import { AppContext } from '../../contexts/app';
import api from '../../services/api';
import Divider from '../Divider';

const defaultCities = [];

function Cities() {
  const { setLoading } = useContext(AppContext);
  const navigation = useNavigate();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data = [] } = await api.get('/cities/top30');

        setCities([
          ...data,
          ...defaultCities.filter(
            (city) => !data.find((dcity) => dcity._id === city._id),
          ),
        ]);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, []);
  return (
    <Row id="cidades" className="align-items-center justify-content-center p-5">
      <Col>
        <div
          className="text-white text-center pt-5 pt-sm-0"
          style={{
            marginBottom: '80px',
          }}
        >
          <h4 className="pt-5 pt-lg-3 mt-4 mt-lg-0">
            Encontre as empresas por cidades
          </h4>
          <Divider />
        </div>
        <Row>
          {cities.map((city, key) => (
            <Col
              key={`${city._id}${key}`}
              xs={12}
              sm={6}
              md={3}
              className="p-0 p-md-3"
            >
              <Button
                color="link"
                className="text-decoration-none text-danger"
                onClick={() => navigation(`/empresas/${city._id}/cidades`)}
              >
                <i className="fas fa-map-marker-alt mr-2" />
                {` ${city._id} (${city.count})`}
              </Button>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
}

export default Cities;
