/* eslint-disable eqeqeq */
import React, { useState, useCallback, useContext, useEffect } from 'react';
// import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Row, NavbarToggler, Navbar, Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, CustomInput } from 'reactstrap';

import { LOCATION_INFORMATION } from '../../constants/app';
import { USER_NAME } from '../../constants/auth';
import { AppContext } from '../../contexts/app';
import { useAuth } from '../../hooks/auth';
// import cepApi from '../../services/api_cep';
import apiLocalidade from '../../services/api_localidade';
// import Divider from '../Divider';

function YourLocation() {
  const navigate = useNavigate();
  const [locationLabel, setLocationLabel] = useState();
  const [foundAddress, setFoundAddress] = useState({});
  // const [foundAddressFeedback, setFoundAddressFeedback] = useState('');
  const {
    setNavbarTogglerOpen,
    handleChangeLocation,
    location,
    setLoading,
  } = useContext(AppContext);
  const { signOut } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState('');
  const [state, setState] = useState('');
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [searchInRegion, setSearchInRegion] = useState(true);

  useEffect(() => {
    setUserName(localStorage.getItem(USER_NAME));
  }, []);

  useEffect(() => {
    const locationInformation = localStorage.getItem(LOCATION_INFORMATION);
    if (location?.city) {
      setShowModal(false);
      setLocationLabel(`${location?.city}, ${location?.state}`);
      setCity({ value: location?.city, label: location?.city });
      setState({
        value: location?.stateId,
        label: location?.stateName,
        sigla: location?.state,
      });
    } else if (locationInformation !== 'INITIAL_NO_INFO') {
      setShowModal(true);
    }
  }, [location]);

  /* const handleSearchZipCode = (searchZipCode) => {
    setFoundAddressFeedback('');
    if (searchZipCode.length < 8) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const { data } = await cepApi.get(`/${searchZipCode}/json`);
        if (data && data.logradouro) {
          setFoundAddress({
            cep: searchZipCode,
            ...data,
          });
          setFoundAddressFeedback(
            `${data.logradouro}, ${data.bairro}, ${data.localidade} - ${data.uf}`,
          );
        } else {
          setFoundAddressFeedback('Endereço não encontrado');
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setFoundAddressFeedback('Endereço não encontrado');
        console.log(err);
      }
    })();
  }; */

  const handleLocation = useCallback(() => {
    // console.log(city, foundAddress);
    if (!city && !foundAddress) {
      return;
    }

    handleChangeLocation(city ? ({
      city: city?.value,
      stateId: state?.value,
      stateName: state.label,
      state: state?.sigla,
      region: city?.region,
      searchInRegion,
    }) : ({
      zipCode: foundAddress?.cep,
      street: foundAddress?.logradouro,
      neighborhood: foundAddress?.bairro,
      city: foundAddress?.localidade,
      state: foundAddress?.uf,
    }));
    setFoundAddress(null);
    setShowModal(false);
  }, [foundAddress, city, state, searchInRegion]);

  const handleLogout = useCallback(() => {
    setLocationLabel('');
    setFoundAddress(null);
    signOut();
    navigate('/');
  }, []);

  const handleOnChangeState = useCallback((stateValue) => {
    setCity(null);
    setState('');

    if (!stateValue?.value) {
      setCities([]);
      return;
    }

    setState(stateValue);

    (async () => {
      try {
        setLoading(true);
        setLoadingCities(true);
        const { data = [] } = await apiLocalidade.get(
          `estados/${stateValue.value}/municipios`,
        );
        setCities(
          data.map((c) => ({
            value: c?.nome,
            label: c?.nome,
            region: c?.microrregiao?.mesorregiao?.nome,
          })),
        );
        setLoading(false);
        setLoadingCities(false);
      } catch (error) {
        setLoading(false);
        setLoadingCities(false);
        console.log(error);
      }
    })();
  }, []);

  const handleOnFocusState = useCallback(() => {
    if (!states.length) {
      (async () => {
        try {
          setLoading(true);
          setLoadingStates(true);
          const { data = [] } = await apiLocalidade.get('estados?orderBy=nome');
          setStates(
            data.map((s) => ({
              value: s?.id,
              label: s?.nome,
              sigla: s?.sigla,
            })),
          );
          setLoading(false);
          setLoadingStates(false);
        } catch (error) {
          setLoading(false);
          setLoadingStates(false);
          console.log(error);
        }
      })();
    }
  }, []);

  const handleOnChangeCity = useCallback((cityValue) => {
    if (!cityValue) {
      setCity(null);
      return;
    }

    setCity(cityValue);
  }, []);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#f8f9fa',
      borderRadius: 50,
      padding: '0.2rem',
      fontSize: '1.25rem',
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: '#2b2b2b',
      color: '#f8f9fa',
      cursor: 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: '#e6332a',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#e6332a',
      },
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#2b2b2b',
    }),
  };

  return (
    <Row
      className="py-2 px-2 px-sm-5 bg-danger justify-content-between align-items-center"
      id="home"
    >
      <div>
        <Button size="sm" title="Sair" onClick={handleLogout}>
          <i className="fas fa-power-off" />
        </Button>
        <span className="p-2">
          Olá Ir.:
          {' '}
          {userName}
        </span>
      </div>
      <Navbar className="d-sm-none" dark>
        <NavbarToggler
          onClick={() => setNavbarTogglerOpen((prevState) => !prevState)}
        />
      </Navbar>
      <Button
        color="link"
        className="p-2 text-white"
        onClick={() => setShowModal(true)}
      >
        <i className="fas fa-map-marker-alt mr-2" />
        {locationLabel ? (
          <>
            Você está em
            {' '}
            {locationLabel}
          </>
        ) : (
          'Informe sua localização'
        )}
        <i className="fas fa-sort-down ml-2" />
      </Button>
      <Modal
        isOpen={showModal}
        onClosed={() => {
          console.log('city', city);
          if (!city) {
            localStorage.setItem(LOCATION_INFORMATION, 'INITIAL_NO_INFO');
          }
        }}
        centered
      >
        <Form>
          <ModalHeader>
            <strong>
              {locationLabel ? 'Alterar Endereço' : 'Informar localização'}
            </strong>
            <Button
              className="close position-absolute top-4 right-4 py-1 px-2"
              onClick={() => setShowModal(false)}
            >
              <i className="fas fa-times" />
            </Button>
          </ModalHeader>
          <ModalBody className="px-4 py-5 px-md-5">
            <Select
              name="states"
              className="basic-single mb-1"
              classNamePrefix="select"
              placeholder="Selecione um estado"
              noOptionsMessage={() => 'Nenhuma estado encontrado'}
              loadingMessage={() => 'Carregando estados'}
              isLoading={loadingStates}
              isClearable
              isSearchable
              value={state}
              options={states}
              styles={selectStyles}
              onFocus={handleOnFocusState}
              onChange={handleOnChangeState}
            />
            <Select
              name="cities"
              className="basic-single mb-3"
              classNamePrefix="select"
              placeholder="Selecione uma cidade"
              noOptionsMessage={() => (!state ? 'Selecione um estado' : 'Nenhuma cidade encontrada')}
              loadingMessage={() => 'Carregando cidades'}
              isLoading={loadingCities}
              isClearable
              isSearchable
              value={city}
              options={cities}
              styles={selectStyles}
              onChange={handleOnChangeCity}
            />
            <FormGroup>
              <CustomInput
                type="checkbox"
                id="searchInRegion"
                label="Habilitar busca por região"
                checked={searchInRegion}
                onChange={() => setSearchInRegion((prevState) => !prevState)}
              />
            </FormGroup>
            {/* <Row className="align-items-center">
              <Divider width="30%" />
              {' ou '}
              <Divider width="30%" />
            </Row>
            <FormGroup className="mt-3">
              <InputGroup className="input-group-merge">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-map-marker-alt" />
                  </InputGroupText>
                </InputGroupAddon>
                <NumberFormat
                  customInput={Input}
                  format="##.###-###"
                  mask="_"
                  type="text"
                  placeholder="Informe o CEP"
                  bsSize="lg"
                  onValueChange={({ value }) => handleSearchZipCode(value)}
                />
              </InputGroup>
            </FormGroup>
            <p className="font-weight-700">{foundAddressFeedback}</p> */}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleLocation} block>
              Definir Localização
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Row>
  );
}

export default YourLocation;
